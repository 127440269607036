import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class Commentation {
    async getComments(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let comments = await axiosService.get(CONSTANTS.APIURL + `commentation-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return comments;
    }
    async getCommentById(id) {
        let comment = await axiosService.get(CONSTANTS.APIURL + `comment?id=${id}` );
        return comment;
    }
    async addComment(...comment) {
        return await axiosService.post(CONSTANTS.APIURL + 'commentation', ...comment);
    }
    async updateComment(...comment) {
        return await axiosService.put(CONSTANTS.APIURL +  'commentation', ...comment);
    }
    async deleteComment(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`commentation?id=${id}`);
    }
    async getComment() {
        return await axiosService.get(CONSTANTS.APIURL +`commentation`);
    }
}
export default new Commentation();