import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class InitialAssessmentService {
    async getInitialAssessment() {
        return await axiosService.get(CONSTANTS.APIURL +`initial-assessments`);
    }
    async getInitialAssessmentById(id) {
        let initialAssessment = await axiosService.get(CONSTANTS.APIURL + `initial-assessments?id=${id}` );
        return initialAssessment;
    }
    async addInitialAssessment(...initialAssessment) {
        return await axiosService.post(CONSTANTS.APIURL + 'initial-assessments', ...initialAssessment);
    }
    async updateInitialAssessment(...initialAssessment) {
        return await axiosService.put(CONSTANTS.APIURL +  'initial-assessments', ...initialAssessment);
    }
    async deleteInitialAssessment(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`initial-assessments?id=${id}`);
    }
    async getInitialAssessmentByArticleId(articleId) {
        return await axiosService.get(CONSTANTS.APIURL + `initial-assessments-by-article_id?article_id=${articleId}`);
    }

    async getInitialAssessmentFilesByInitialAssessmentId(initialAssessmentId) {
        return await axiosService.get(CONSTANTS.APIURL + `initial-assessment-files-by-initial-assessment-id?initial_assessment_id=${initialAssessmentId}`);
    }

    async getInitialAssessmentFilesByPath(path) {
        let articleFile = await axiosService.get(CONSTANTS.APIURL + `initial-assessment-files-by-path?filepath=${path}`);
        return articleFile;
    }
    async deleteInitialAssessmentFile(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`initial-assessment-files?id=${id}`);
    }

}

export default new InitialAssessmentService()