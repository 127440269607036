import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class JournalsService {
    async getJournals(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let inspectors = await axiosService.get(CONSTANTS.APIURL + `journals-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return inspectors;
    }
    async getJournalById(id) {
        let inspectors = await axiosService.get(CONSTANTS.APIURL + `journal?id=${id}` );
        return inspectors;
    }
    async addJournal(...inspector) {
        return await axiosService.post(CONSTANTS.APIURL + 'journals', ...inspector);
    }
    async updateJournal(...inspector) {
        return await axiosService.put(CONSTANTS.APIURL +  'journals', ...inspector);
    }
    async deleteJournal(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`journals?id=${id}`);
    }
    async getJournal() {
        return await axiosService.get(CONSTANTS.APIURL +`journal`);
    }
}
export default new JournalsService()