import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class UploadService {
    async uploadfile(...documents) {
        return await axiosService.post(CONSTANTS.APIURL + 'add-form-upload', ...documents, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          });
    }

    async deletefile(...documents) {
        return await axiosService.post(CONSTANTS.APIURL + 'add-form-delete-file', ...documents);
    }

    async uploadDocumentForInitailAssessments(...documents) {
        return await axiosService.post(CONSTANTS.APIURL + 'initial-assessment-files', ...documents,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          });
    }
}

export default new UploadService();