import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class FormService {
    async addform(...form) {
        return await axiosService.post(CONSTANTS.APIURL + 'add-form', ...form);
    }
    async updateform(...form) {
        return await axiosService.put(CONSTANTS.APIURL + 'update-form', ...form);
    }
    async getInitialAssessmentForm() {
        return await axiosService.get(CONSTANTS.APIURL +`form-initial-assessment`);
    }
    async getInitialAssessmentForm() {
        return await axiosService.get(CONSTANTS.APIURL +`form-initial-assessment`);
    }
    async getInitialAssessmentForms(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : "";
        let assessmentForms = await axiosService.get(CONSTANTS.APIURL + `form-initial-assessment-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return assessmentForms;
    }

    async getScopeOfContents() {
        return await axiosService.get(CONSTANTS.APIURL +`scope-of-contents`);
    }
    async updateScopeOfContents(...updateContent) {
        return await axiosService.put(CONSTANTS.APIURL +`form-scope-of-content`, ...updateContent);
    }

    async addIASFForm(forms) {
        return await axiosService.post(CONSTANTS.APIURL +`form-initial-assessment`,forms);
    }
    async getDetailsIASFFormById(articleId) {
        return await axiosService.get(CONSTANTS.APIURL +`form-initial-assessment-details?articleId=${articleId}`);
    }
    async updateIASFForm(forms) {
        return await axiosService.put(CONSTANTS.APIURL +`form-initial-assessment`,forms);
    }
}

export default new FormService();