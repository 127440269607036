import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class ArticleStatusService {
    async getArticlesStatus(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : "";
        let articles = await axiosService.get(CONSTANTS.APIURL + `article-status-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return articles;
    }
    async getArticleStatusById(id) {
        let article = await axiosService.get(CONSTANTS.APIURL + `article-status?id=${id}`);
        return article;
    }
    async addArticleStatus(...article) {
        return await axiosService.post(CONSTANTS.APIURL + 'article-status', ...article);
    }
    async updateArticleStatus(...article) {
        return await axiosService.put(CONSTANTS.APIURL + 'article-status', ...article);
    }
    async deleteArticleStatus(id) {
        return await axiosService.detete(CONSTANTS.APIURL + `article-status?id=${id}`);
    }
    async getArticleStatus() {
        return await axiosService.get(CONSTANTS.APIURL + `article-status`);
    }
}
export default new ArticleStatusService();