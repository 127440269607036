import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class ArticleService {
    async getArticles(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : "";
        let articles = await axiosService.get(CONSTANTS.APIURL + `articles-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return articles;
    }
    async getArticleById(id) {
        let article = await axiosService.get(CONSTANTS.APIURL + `article?id=${id}`);
        return article;
    }
    async addArticle(...article) {
        return await axiosService.post(CONSTANTS.APIURL + 'articles', ...article);
    }
    async updateArticle(...article) {
        return await axiosService.put(CONSTANTS.APIURL + 'articles', ...article);
    }
    async deleteArticle(id) {
        return await axiosService.detete(CONSTANTS.APIURL + `articles?id=${id}`);
    }
    async getArticle() {
        return await axiosService.get(CONSTANTS.APIURL + `articles`);
    }

    async getArticleAuthorsByArticleId(articleId) {
        let articleAuthors = await axiosService.get(CONSTANTS.APIURL + `article-authors-by-article_id?article_id=${articleId}`);
        return articleAuthors;
    }

    async getArticleAuthorsFilesByArticleId(articleId) {
        let articleAuthorFiles = await axiosService.get(CONSTANTS.APIURL + `article-files-by-article-id?article_id=${articleId}`);
        return articleAuthorFiles;
    }

    async getArticleExpertsByArticleId(articleId) {
        let articleExperts = await axiosService.get(CONSTANTS.APIURL + `article-experts-by-article_id?article_id=${articleId}`);
        return articleExperts;
    }

    async getArticleFileById(id) {
        let articleFile = await axiosService.get(CONSTANTS.APIURL + `article-file?id=${id}`);
        return articleFile;
    }

    async getArticleFileByPath(path) {
        let articleFile = await axiosService.get(CONSTANTS.APIURL + `article-file-by-path?filepath=${path}`);
        return articleFile;
    }

    async deleteArticleFile(id) {
        return await axiosService.detete(CONSTANTS.APIURL + `article-files?id=${id}`);
    }
    
   
}
export default new ArticleService();